<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>
              Generación de reporte
              <span class="d-block text-muted pt-2 font-size-sm"
                >Reporte de estudiantes inscritos por nivel académico</span
              >
            </h3>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->

      <div class="card-body px-1 px-sm-5">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::cards with generator button for each report -->
              <template>
                <v-row>
                  <!-- begin::excel with students scores col-container-->
                  <v-col cols="12" sm="8" md="8" lg="6" xl="4">
                    <!-- begin::excel with students scores-->
                    <v-card outlined>
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">estudiantes</div>
                          <v-list-item-title class="text-h5 mb-1">
                            Reporte
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Estudiantes inscritos por nivel
                            académico.</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded"
                          tile
                          icon
                          size="80"
                          color="success"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-microsoft-excel</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          outlined
                          rounded
                          text
                          :href="downloadReport"
                          download
                        >
                          Generar reporte
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                  </v-col>
                  <!-- end::excel with students scores col-container-->
                </v-row>
              </template>
              <!-- end::cards with generator button for each report -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "StudentEnrolledResume",
  title: "Estudiantes inscritos por nivel académico | GE ITR",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes", route: "reports" },
      {
        title: "Estudiantes incritos por nivel académico",
        route: "student_enrolled_resume",
      },
    ]);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    reportsApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },
    downloadReport() {
      let url = `${this.reportsApiUrl}/students/resume-enrolled`;
      return url;
    },
  },
};
</script>

<style></style>
